import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import {useCheckExistingEmailEvent, useLoginEvent} from "~/composables/useEventBus";
import type {EmailRb} from "~/models/request/emailRb";
import type {LoginRb} from "~/models/request/loginRb";
import type {User} from "~/models/user";
import type {NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";
import type {addCoHostRB, AddUpdateUser} from "~/models/request/addCoHostRb";
import {UserRole} from "~/utils/enums/UserRole";
import {ApplicationType} from "~/utils/enums/ApplicationType";
import {UserStatus} from "~/utils/enums/UserStatus";
import moment from "moment";
import {SortBy} from "~/utils/enums/SortBy";
import {isCurrentPath, isMenuOpen, sortedListByDBDateTimeKey} from "~/utils/helpers";
import {companyStore} from "~/store/companyStore";
import type {DeleteUserRb} from "~/models/request/deleteUserRb";
import {UserIcon} from "@heroicons/vue/24/outline";
import type {CompanyDetails} from "~/models/companyDetails";
import {CompanyModule} from "~/utils/enums/CompanyModule";

export const userStore = defineStore({
    id: 'user-store',
    persist: true,
    state: () => {
        return {
            loggedInUser: <User>{},
            eventRegistrationLoggedInUser: <User>{},
            rsvpGuestUser: <User>{},
            allUsersOfCurrentCompany: <User[]>[],
            allAdminsByCompanyId: <User[]>[]
        }
    },
    actions: {
        clearUserStore: function () {
            try {
                this.loggedInUser = <User>{profilePicture: '', FirstName: '', LastName: ''};
                this.eventRegistrationLoggedInUser = <User>{};
                this.allAdminsByCompanyId = <User[]>[];
                this.clearAllUsersOfCurrentCompany();
                this.clearRsvpGuestUser();
            } catch (e) {
                console.log(e)
            }
        },
        clearAllUsersOfCurrentCompany: function () {
            this.allUsersOfCurrentCompany = [];
        },
        clearRsvpGuestUser: function () {
            this.rsvpGuestUser = <User>{};
        },
        checkForExistingParentEmail: async function (email: string) {
            useGlobalEvent(useCheckExistingEmailEvent(), <any>{
                fetching: true,
            })
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Parent/CheckForExistingParentEmail';
            const ns = new NetworkService();

            let {parent} = await ns.post$fetch(url, {"email": email}, null, "CheckForExistingParentEmail" + new Date().getMilliseconds() + "_")
            useGlobalEvent(useCheckExistingEmailEvent(), <any>{
                fetching: false,
            })
            return parent;
        },

        getUserDetailsByEmail: async function (email: string, companyId: number) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/api/user/getuserdetails';
            const ns = new NetworkService();

            let {data} = await ns.post$fetch(url, {
                "Email": email,
                "company_id": companyId
            }, null, "GetUserDetails" + new Date().getMilliseconds() + "_")
            if (data.id) {
                return data;
            } else {
                return false;
            }
        },

        getUsersByEmails: async function (emails: string[], companyId: number, st: string) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/api/user/getUsersByEmails';
            const ns = new NetworkService();
            try {
                let { data } = await ns.post$fetch(url, {
                    "Emails": emails,
                    "company_id": companyId,
                    "st": st
                }, null, "GetUsersByEmails" + new Date().getMilliseconds() + "_");
                return data;
            } catch(err) {
                console.log("Error - getUsersByEmails: ", err)
            }

        },

        getUserDetailsByEmailAndCompanyIdServer: async function (email: string, companyId: number) {
            const config = useRuntimeConfig()
            let url = '/api/user/user-details-by-email-and-company-id';
            const ns = new NetworkService();

            let {data} = await ns.post$fetch(url, {
                "Email": email,
                "company_id": companyId
            }, null, "getUserDetailsByEmailAndCompanyIdServer" + new Date().getMilliseconds() + "_", false, true)
            if (data.id) {
                return data;
            } else {
                return false;
            }
        },

        loadUsersByCompanyId: async function (companyId: number) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/api/user/getAllUsersByCompanyId';
            const ns = new NetworkService();

            let {data} = await ns.post$fetch(url, {company_id: companyId}, null, "getUsersByCompanyId" + new Date().getMilliseconds() + "_")
            if (data) {
                this.clearAllUsersOfCurrentCompany();
                const {users} = data;
                //Population store variable can be done here because this function will be called only once when user logs in
                for (let user of users) {
                    this.allUsersOfCurrentCompany.push(user as User);
                }
                return data;
            } else {
                return false;
            }
        },

        userLogin: async function (loginRb: LoginRb) {
            useGlobalEvent(useLoginEvent(), <any>{
                loading: true,
            })
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/SignupService.svc/Login';
            const ns = new NetworkService();

            let {Result} = await ns.post$fetch(url, loginRb, null, "Login" + new Date().getMilliseconds() + "_")
            useGlobalEvent(useLoginEvent(), <any>{
                loading: false,
            })

            if (Result) {
                this.loggedInUser = Result as User
            }
            return this.loggedInUser;
        },

        sendEmail: async function (emailRb: EmailRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/api/email/sendEmail';
            const ns = new NetworkService();

            let Result = await ns.post$fetch(url, emailRb, null, "SendEmail" + new Date().getMilliseconds() + "_");
            return Result;
        },

        getGoogleUserInfo: async function (
            token:string,
            byAccessToken:boolean=false,  /*True for custom google button*/
        ) {
            const config = useRuntimeConfig()
            const ns = new NetworkService();
            if(byAccessToken){
                let url = '/api/google/user';
                return await ns.post$fetch(url, {
                    'access_token': token,
                }, null, "");
            }else{
                let url = config.public.BASE_URL_HC_MESSAGING + '/public/v1/with-google-token';
                let {result} = await ns.post$fetch(url, {token: token}, null, "GoogleLogin" + new Date().getMilliseconds() + "_", false, true)
                return result;
            }
        },

        setLoggedInUser: function (user: User) {
            this.loggedInUser = user;
        },

        setEventRegistrationLoggedInUser: function (user: User) {
            this.eventRegistrationLoggedInUser = user;
        },

        setRsvpGuestUser: function (user: User) {
            this.rsvpGuestUser = user;
        },

        changeUserPassword: async function (id: number, email: string, oldPassword: string, newPassword: string) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/api/user/resetPassword';
            const ns = new NetworkService();

            let {data} = await ns.post$fetch(url, {
                "id": id,
                "Email": email,
                "oldPassword": oldPassword,
                "newPassword": newPassword
            }, null, "changeUserPassword" + new Date().getMilliseconds() + "_")

            if (data == "Password changed successfully") {
                return data;
            } else {
                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                    type: NotificationType.ERROR,
                    title: 'Error',
                    message: data,
                })
            }
        },
        uploadProfilePicture: async function (fileName: string, fileType: string, fileStream: any, companyId: number) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/picture/uploadPicture';
            const ns = new NetworkService();

            let data = await ns.post$fetch(url, {
                "FileName": fileName,
                "FileType": fileType,
                "FileStream": fileStream,
                "companyId": companyId
            }, null, "uploadProfilePicture" + new Date().getMilliseconds() + "_")
            return data;
        },
        addUpdateUser: async function (user: AddUpdateUser, fromServer: boolean = false) {
            const config = useRuntimeConfig();
            let url = fromServer ? '/api/user/add-update-user' : config.public.BASE_URL_SUB + '/api/user/save';
            const ns = new NetworkService();
            return await ns.post$fetch(url, {...user}, null, "addUpdateUser" + new Date().getMilliseconds() + "_", false, fromServer);
        },
        updateUser: async function (user: AddUpdateUser, fromServer: boolean = false) {
            const config = useRuntimeConfig();
            let url = fromServer ? '/api/user/add-update-user' : config.public.BASE_URL_SUB + '/api/user/update';
            const ns = new NetworkService();
            return await ns.post$fetch(url, {...user}, null, "addUpdateUser" + new Date().getMilliseconds() + "_", false, fromServer);
        },
        addUpdateCoHost: async function (user: addCoHostRB) {
            const config = useRuntimeConfig();
            let url = config.public.BASE_URL_SUB + '/api/user/save';
            const ns = new NetworkService();
            let {data} = await ns.post$fetch(url, {...user}, null, "addUpdateUser" + new Date().getMilliseconds() + "_");
            return data;
        },
        getAllAdminByCompanyId: async function (companyId: number) {
            const config = useRuntimeConfig();
            let url = config.public.BASE_URL_SUB + '/api/user/getAllAdminsByCompanyId';
            const ns = new NetworkService();
            let data = await ns.post$fetch(url, {'company_id': companyId}, null, "getAllAdminByCompanyId" + new Date().getMilliseconds() + "_");
            console.log(data)
            return data;
        },
        checkEmailExistInUser: async function (email: string) {
            const config = useRuntimeConfig();
            let url = config.public.BASE_URL_SUB + '/SignUpService.svc/api/user/checkEmailExist';
            const ns = new NetworkService();
            let data = await ns.post$fetch(url, {'email': email}, null, "checkEmailExist" + new Date().getMilliseconds() + "_");
            return data;
        },
        setAdminsByCompanyId: function (users: User[]) {
            this.allAdminsByCompanyId = users;
        },
        setNewAdmin: function (user: User) {
            if (!this.allAdminsByCompanyId.find((item) => item.id == user.id)) {
                this.allAdminsByCompanyId.push(user);
                console.log(this.allAdminsByCompanyId)
            }
        },

        loadAndSetRsvpGuestUserFromServer: async function (email: string, companyId: number) {
            const userResponse = await this.getUserDetailsByEmailAndCompanyIdServer(email, companyId);
            if (userResponse) {
                this.setRsvpGuestUser(userResponse as User);
            }
        },
        createUpdateUser: async function (user: AddUpdateUser, fromServer: boolean = false) {
            const config = useRuntimeConfig();
            let url = fromServer ? '/api/user/create-update-user' : config.public.BASE_URL_SUB + '/api/user/createUpdateUser';
            const ns = new NetworkService();
            return await ns.post$fetch(url, {...user}, null, "addUpdateUser" + new Date().getMilliseconds() + "_", false, fromServer);
        },
        deleteUser: async function (deleteUserRb: DeleteUserRb, fromServer: boolean = false) {
            const config = useRuntimeConfig();
            let url = fromServer ? '/api/user/delete' : config.public.BASE_URL_SUB + '/api/user/delete';
            const ns = new NetworkService();
            return await ns.post$fetch(url, deleteUserRb, null, "deleteUser" + new Date().getMilliseconds() + "_", false, fromServer);
        },
        addUpdateUserAddress: async function(userAddressRb:any){
            const config = useRuntimeConfig()
            let url = '/api/user/add-update-user-address';
            const ns = new NetworkService();
            let {data} = await ns.post$fetch(url, userAddressRb, null, "addUpdateUser" + new Date().getMilliseconds() + "_")
            return data;
        },
        getUserDetailsByEmailAndCompanyId: async function (email:string, companyId:number, fromServer:boolean=false) {
            const config = useRuntimeConfig()
            const ns = new NetworkService();
            let url;
            if(fromServer){
                url = '/api/user/user-details-by-email-and-company-id'
            }else{
                url = config.public.BASE_URL_SUB + '/api/user/getuserdetails';
            }

            let {data} = await ns.post$fetch(url, {"Email":email, "company_id": companyId}, null, "GetUserDetails" + new Date().getMilliseconds() + "_")
            if(data.id){
                return data;
            }else{
                return false;
            }
        },
        addSingleUserToAllUsersOfCurrentCompany: function (user:User)  {
            this.allUsersOfCurrentCompany.push(user)
        },
        getSidebarNavigations: function (user: User, companyDetails:CompanyDetails) {
            const config = useRuntimeConfig();
            const useCompanyStore = companyStore();
            const useUserStore = userStore();
            const companyId = companyDetails.id
            const isAdmin = useUserStore.hasRole(user, companyId, UserRole.ROLE_SC_ADMIN);
            const isStaff = useUserStore.hasRole(user, companyId, UserRole.ROLE_SC_TEACHER);
            const isStudent = useUserStore.hasRole(user, companyId, UserRole.ROLE_SC_STUDENT);
            const hasEventModule = useCompanyStore.hasModule(companyDetails, CompanyModule.Event);
            const hasPaymentModule = useCompanyStore.hasModule(companyDetails, CompanyModule.Payment);
            let navigation: any = [
                {
                    name: 'Dashboard',
                    href: '/dashboard',
                    icon: 'material-symbols:dashboard-rounded',
                    current: true,
                    app: `${ApplicationType.PROGRAM}|${ApplicationType.EVENT}`,
                    companyException: [4282, 4283, 4291, 4262, 4291, 4140, 4257],
                    show: isAdmin || isStaff,
                }, {
                    name: 'Programs',
                    href: '/programs',
                    icon: 'material-symbols-light:list-alt-outline',
                    current: false,
                    app: `${ApplicationType.PROGRAM}`,
                    show: isAdmin || isStaff,
                }, {
                    name: 'Students',
                    href: '/participants/all',
                    icon: 'ph:student-fill',
                    current: false,
                    app: `${ApplicationType.PROGRAM}`,
                    show: isAdmin || isStaff,
                },
                {
                    name: 'Participant Directory',
                    href: '/database/records/list',
                    icon: 'ph:database',
                    current: false,
                    app: `${ApplicationType.EVENT}}`,
                    companyException: [4282, 4283, 4291, 4262, 4291, 4140, 4257],
                    show: isAdmin || isStaff,
                },
                {
                    name: 'Users',
                    href: '/users',
                    icon: 'ion:people',
                    current: false,
                    app: `${ApplicationType.PROGRAM}|${ApplicationType.EVENT}`,
                    companyException: [4282, 4283, 4291, 4262, 4291, 4140, 4257],
                    show: isAdmin || isStaff,
                },
                {
                    name: 'Families',
                    href: '/families',
                    icon: 'material-symbols:family-restroom',
                    current: false,
                    app: `${ApplicationType.PROGRAM}`,
                    show: isAdmin || isStaff,
                },
                {
                    name: 'Transaction',
                    href: '/transaction',
                    icon: 'material-symbols:compare-arrows-rounded',
                    current: false,
                    app: `${ApplicationType.EVENT}|${ApplicationType.PROGRAM}`,
                    show: (isAdmin) && hasPaymentModule,
                },
                {
                    name: 'Events',
                    href: '/events',
                    icon: 'material-symbols:event-available-outline-rounded',
                    current: false,
                    app: `${ApplicationType.EVENT}|${ApplicationType.PROGRAM}`,
                    show: hasEventModule && (isAdmin || isStaff || isStudent),
                },
                {
                    name: 'Text Messaging',
                    icon: 'tabler:message',
                    trailIcon: 'material-symbols:chevron-right',
                    current: false,
                    app: `${ApplicationType.PROGRAM}`,
                    open: isMenuOpen([
                        '/text-messaging/plans',
                        '/text-messaging/usage-history'
                    ]),
                    children: [
                        { name: 'Recharge', href: '/text-messaging/plans', icon: 'ri:exchange-dollar-fill', current: isCurrentPath('/text-messaging/plans') },
                        { name: 'Usage History', href: '/text-messaging/usage-history', icon: 'material-symbols:data-usage', current: isCurrentPath('/text-messaging/usage-history') },
                    ],
                    show: isAdmin,
                },
                {
                    name: 'Announcements',
                    href: '/announcements',
                    icon: 'material-symbols:campaign',
                    current: false,
                    app: `${ApplicationType.PROGRAM}`,
                    show: isAdmin || isStaff,
                },
                {
                    name: 'Payment History',
                    href: '/payments/history',
                    icon: 'ep:money',
                    current: false,
                    app: `${ApplicationType.EVENT}`,
                    show: hasEventModule && isStudent,
                },
                {
                    name: 'Payment History',
                    href: '/payments/history-for-program',
                    icon: 'ep:money',
                    current: false,
                    app: `${ApplicationType.PROGRAM}`,
                    show: isAdmin || isStaff,

                },
            ];
            navigation = navigation.filter((item: any) => item.show==true);
            navigation = navigation.filter((item: any) => item.app?.includes(config.public.APP_TYPE));
            return navigation.filter((item: any)=>{
                if(item.companyException){
                    const excepted = item.companyException?.includes(useCompanyStore.getCompanyDetailsLocal.id);
                    return !excepted;
                }else{
                    return true;
                }
            });
        },
        hasRole: function (user:User, companyId:number, role:UserRole){
            let hasRole = false;
            try {
                if (user?.id) {
                    const currentCompanyRoles = user?.allRoles[companyId] ?? [];
                    for (const singleRole of currentCompanyRoles ?? []) {
                        if (singleRole.RoleId == role) {
                            hasRole = true;
                            break;
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            return hasRole;
        },
    },
    getters: {
        getLoggedInUser: state => state.loggedInUser,
        getEventRegistrationLoggedInUser: state => state.eventRegistrationLoggedInUser,
        getRsvpGuestUser: state => state.rsvpGuestUser as User,
        getAllUsersOfCurrentCompany: state => state.allUsersOfCurrentCompany,
        getRoleBasedUsersOfCurrentCompany: state => function (roleIds:any[]) {
          return state.allUsersOfCurrentCompany.filter((user:User)=> {
              for (const roleId of roleIds) {
                 if(user.roleIds?.includes(roleId)){
                     return user;
                 }
              }
          })
        },
        getAllActiveUsersOfCurrentCompany: state => function (sortBy?: SortBy) {
            let activeUsers = state.allUsersOfCurrentCompany.filter((user: User) => user.status == UserStatus.ACTIVE)
            if (sortBy == SortBy.LAST_LOG_IN) {
                sortedListByDBDateTimeKey(activeUsers, "lastLoggedIn");
            }
            return activeUsers;
        },
        getCustomActiveUsersOfCurrentCompany: state => function (quantity: number) {
            let activeUsers = state.allUsersOfCurrentCompany.filter((user: User) => user.status == UserStatus.ACTIVE)
            sortedListByDBDateTimeKey(activeUsers, "lastLoggedIn");
            return activeUsers.length <= quantity ? activeUsers : activeUsers.slice(0, quantity);
        },
        getAllAdminsOfCurrentCompany: state => state.allAdminsByCompanyId,
        getUserByIdFromUsersOfCurrentCompany: state => function (userId: number) {
            return state.allUsersOfCurrentCompany.find((user: User) => user.id == userId)
        },
        getUserById: state => function (userId: number) {
            return state.allUsersOfCurrentCompany.find((item) => item.id == userId)
        },
        getAdminById: state => function (userId: number) {
            return state.allAdminsByCompanyId.find((item) => item.id == userId)
        },
        getUserByEmailFromCompanyUserList: state => function (userEmail: string) {
            return state.allUsersOfCurrentCompany.find((item) => item.Email == userEmail)
        },
        isLoggedInUserAnAdminOfThisCompany: state => function (companyId: number) {
            let isAnAdmin = false;
            try {
                if (state.loggedInUser?.id) {
                    const currentCompanyRoles = state.loggedInUser.allRoles[companyId] ?? [];
                    for (const singleRole of currentCompanyRoles ?? []) {
                        if (singleRole.RoleId == UserRole.CompanyAdmin) {
                            isAnAdmin = true;
                            break;
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            return isAnAdmin;
        },
        isLoggedInUserAStaffOfThisCompany: state => function (companyId: number) {
            let isAdminStaff = false;
            try {
                if (state.loggedInUser?.id) {
                    const currentCompanyRoles = state.loggedInUser.allRoles[companyId] ?? [];
                    for (const singleRole of currentCompanyRoles ?? []) {
                        if (singleRole.RoleId == UserRole.ROLE_SC_TEACHER) {
                            isAdminStaff = true;
                            break;
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            return isAdminStaff;
        },
    },
})
