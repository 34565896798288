import type { Moment } from "moment";
// import moment from "moment";
// import type { Moment } from "moment";
import moment from 'moment-timezone';
import {Mask} from "maska";
import {UserRole} from "~/utils/enums/UserRole";
import {PlanUsageCategory} from "~/utils/enums/PlanUsageCategory";

const getDateFromDBDate = (date: number) => {
    return moment(String(date)).format('MM/DD/YYYY');
}


//20231207  =  2023-12-07
const getDateFromString = (date: number) => {
    let newDate = String(date);
    return newDate.substring(0, 4) + "-" + newDate.substring(4, 6) + "-" + newDate.substring(6)
}
const getDateTimeStringFromDate = (date: Date, format: string = 'MM/DD/YYYY  hh:mm A') => {
    if (moment(date).isValid())
        return moment(String(date)).format(format ?? 'MM/DD/YYYY  hh:mm A');
    else return ''
}
const getDateFromUnixTime = (unixTime: number, format: string) => {
    return moment(unixTime).format(format ?? 'ddd, MMM Do, YYYY hh:mm A');
}

//20230725 => December 29, 2022
const getDateFromDBDate1 = (date: string) => {
    try {
        return moment(date, 'YYYYMMDD').format('MMM DD, YYYY');
    } catch (e) {
        console.log(e);
        return '';
    }
}

//202307250001 => December 29, 2022
const getDateFromDBDate2 = (date: string) => {
    try {
        return moment(date, 'YYYYMMDDHHmm').format('MMM DD, YYYY');
    } catch (e) {
        console.log(e);
        return '';
    }
}

//202307250001 => December 29, 2022 . 1:25 am
const getDateFromDBDate7 = (date: string) => {
    try {
        return moment(date, 'YYYYMMDDHHmm').format('MMMM DD, YYYY . h:mm a');
    } catch (e) {
        console.log(e);
        return '';
    }
}
//20230725 => 2024-01-01
const getFormattedDateFromDateString = (date: string) => {
    try {
        return moment(date, 'YYYYMMDD').format('YYYY-MM-DD');
    } catch (e) {
        console.log(e);
        return '';
    }
}
const getFormattedDateFromDateString1 = (date: string) => {
    try {
        return moment(date, 'YYYYMMDD').format('DD MMM');
    } catch (e) {
        console.log(e);
        return '';
    }
}
const getOnlyDayFromDateString = (date: string) => {
    try {
        return moment(date, 'YYYYMMDD').format('DD');
    } catch (e) {
        console.log(e);
        return '';
    }
}

//2024-01-05T13:57:34.254Z => January 2, 02:45 PM
const getDateFromDBDate3 = (date: string) => {
    try {
        return moment(date).format('MMMM D, hh:mm A');
    } catch (e) {
        console.log(e);
        return '';
    }
}

//2024-01-05T13:57:34.254Z => 2/15/2023
const getDateFromDBDate8 = (date: string) => {
    try {
        return moment(date).format('M/D/YYYY');
    } catch (e) {
        console.log(e);
        return '';
    }
}


//2024-01-05T13:57:34.254Z => January 2, 02:45 PM
const getDateStringFromDBDate3 = (date: string) => {
    try {
        return moment(date).format('hh:mm A, MMM DD YYYY');
    } catch (e) {
        console.log(e);
        return '';
    }
}

//2024-01-05T13:57:34.254Z => January 2, 02:45 PM
const getDateStringFromDBDate = (date: string) => {
    try {
        return moment(date).format('MM/DD/YYYY');
    } catch (e) {
        console.log(e);
        return '';
    }
}

//2024-01-05T13:57:34.254Z => 02:45 PM, JAN 2, 2024
const getDateFromDBDate4 = (date: string) => {
    try {
        return moment(date).format('hh:mm A, MMM D, YYYY');
    } catch (e) {
        console.log(e);
        return '';
    }
}

const getDateAgo = (date: string) => {
    try {
        return moment(date).fromNow();
    } catch (e) {
        console.log(e);
        return '';
    }
}

//2024-01-05T13:57:34.254Z => JAN 2, 2024 02:45 PM
const getDateFromDBDate5 = (date: string) => {
    try {
        return moment(date).format('MMM D, YYYY hh:mm A');
    } catch (e) {
        console.log(e);
        return '';
    }
}

//2024-01-05T13:57:34.254Z => JAN 2, 02:45 PM
const getDateFromDBDate6 = (date: string) => {
    try {
        return moment(date).format('MMM D, hh:mm A');
    } catch (e) {
        console.log(e);
        return '';
    }
}

//202406261349 => JAN 2, 2024 02:45 PM
const getDateFromDBDateToMMDDYYTime = (date: string) => {
    try {
        // Specify the input format 'YYYYMMDDHHmm'
        const parsedDate = moment(date, 'YYYYMMDDHHmm');
        // Format the parsed date into 'MMM D, YYYY hh:mm A'
        return parsedDate.format('MMM D, YYYY hh:mm A');
    } catch (e) {
        console.log(e);
        return '';
    }
}


//2024-01-05T13:57:34.254Z => JAN 2, 02:45 PM
const getDateTimeFromMoment = (moment: Moment) => {
    try {
        return moment.format('YYYY-MM-DDThh:mm:ss');
    } catch (e) {
        console.log(e);
        return '';
    }
}

//202401131755 => Moment
const getMomentDateFromDBDate = (date: string) => {
    try {
        return moment(date, 'YYYYMMDDHHmm');
    } catch (e) {
        console.log(e);
        return moment();
    }
}

const getMomentFromDateString = (date: string) => {
    try {
        return moment(date, 'YYYYMMDD');
    } catch (e) {
        console.log(e);
        return moment();
    }
}
//2024-03-13T19:34:45.566Z => YYYYMMDD
const getStringFromDate = (date: string) => {
    try {
        return moment(date).format('YYYYMMDD');
    } catch (e) {
        console.log(e);
        return ;
    }
}

const getDateFromDBDateTime = (date: number) => {
    const dateTimeString = date + '';
    const onlyDateString = dateTimeString.substring(0, 8);
    return moment(onlyDateString, 'YYYYMMDD').format('MM/DD/YYYY');
}


//2000 => 08:00 PM
const getTimeFromDBTime = (time: number) => {
    let stringTime = time.toString();
    if (stringTime.length == 3) {
        stringTime = '0' + stringTime;
    }
    try {
        if (moment(stringTime, 'hhmm').isValid())
            return moment(stringTime, 'hhmm').format('hh:mm A');
    } catch (e) {
        console.log(e)
    }
}

const getTimeFromDBDateTime = (dateTime: string) => {
    if (moment(dateTime, 'YYYYMMDDHHmm').isValid())
        return moment(dateTime, 'YYYYMMDDHHmm').format('hh:mm A');
}

const getPaymentPeriodByDBDate = (date: number) => {
    return moment(date, 'YYYYMMDD').format('MMMM YYYY');
}

//Jun 31, 2023 => 202307311227
const getDBDateFromDateStringStartOrEndMinute = (dateString: string, isStartOfTheDay: boolean) => {
    if (isStartOfTheDay) {
        dateString = dateString + ' 0001';
    } else {
        dateString = dateString + ' 2359'
    }
    return moment(dateString, 'MMM DD YYYY HHmm').format('YYYYMMDDHHmm');
}
const getDBDateFromDateStringStartOrEndMinute2 = (dateString: string, isStartOfTheDay: boolean) => {
    if (isStartOfTheDay) {
        dateString = dateString + '0001';
    } else {
        dateString = dateString + '2359'
    }
    return moment(dateString, 'YYYYMMDDHHmm').format('YYYYMMDDHHmm');
}

//Jun 31 2023
const getTodayDateTimeString = () => {
    return moment().format('MMM DD YYYY');
}
const getTodayString = () => {
    return moment().format('YYYY-MM');
}

const getCurrentYearString = () => {
    return moment().format('YYYY');
}

//Jun 31 2023
const getBefore7DayDateTimeString = () => {
    return moment().subtract(7, 'days').format('MMM DD YYYY');
}
//date from days Ago
const getDateBeforeDate = (days:number) => {
    return moment().subtract(days, 'days');
}
//10:20 => 1020
const getTimeFromStringTime = (time: string) => {
    try {
        const stringTime = time
        const stringTimeArray = stringTime.replaceAll(':', '')
        const numberTime = Number(stringTimeArray)
        return numberTime
    } catch (e) {
        console.log(e);
        return '';
    }
}

//1020 => 10:20
const getStringFromTime = (time: number) => {
    try {
        const stringTime = time.toString()
        const formattedTime = stringTime.substring(0, 2) + ":" + stringTime.substring(2);
        return formattedTime;
    } catch (e) {
        console.log(e);
        return '';
    }
}
//2023-11-28  =  20231128
const getDateFromStringDate = (date: string) => {
    try {
        const stringDate = date
        const stringDateArray = stringDate.replaceAll('-', '')
        const numberDate = Number(stringDateArray)
        return numberDate
    } catch (e) {
        console.log(e);
        return '';
    }
}

const getCurrentTimeDbFormat = (hoursFromNow: number = 0) => {
    try {
        const currentTime = moment().add(hoursFromNow, 'hours');
        return currentTime.format('YYYYMMDDHHmm')
    } catch (e) {
        console.log(e)
        return '';
    }
}
// 2024-02-15 T 00:02 => 202402150002
const getStringDateFormat = (date: string) => {
    try {
        return moment().format('YYYYMMDDHHmm')
    } catch (e) {
        console.log(e)
        return '';
    }
}

//202307250001 => Moment
const getMomentFromDBDate = (date: string|undefined) => {
    try {
        return moment(date, 'YYYYMMDDHHmm');
    } catch (e) {
    }
}

const getStartDateOfCurrentMonthDbFormat = (currentDate:string) => {
    try {
        const currentMonth = currentDate.slice(0, 6)+"010000";
       return  moment(currentMonth, 'YYYYMMDDHHmm').format('YYYYMMDDHHmm')
    } catch (e) {
        console.log(e)
        return '';
    }
}

const getATemporaryEmail = () => {
    const randomNumber = Math.random();
    return 'technuf_' + moment().unix() + 'r' + randomNumber + '@technuf.org'
}

const isTemporaryEmail = (email: string) => {
    if (email){
    return email.startsWith('technuf_');
    }
    return false
}

const validateEmail = (email: string) => {
    return email.toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

const isValidEmail = (email:string) => {
    return validateEmail(email)?.length??0>0;
}

const sortedListByDBDateTimeKey = (list:any[], key:string) => {
    list.sort((a, b) => {
        if (a[key] === null && b[key] !== null) {
            return 1;
        } else if (a[key] !== null && b[key] === null) {
            return -1;
        } else if (a[key] !== null && b[key] !== null) {
            const aLastLogin = moment(a[key]);
            const bLastLogin = moment(b[key]);
            return bLastLogin.diff(aLastLogin); // Sort by most recent first
        }
        return 0;
    });
    return list;
}

function getPhoneWithCountryCode(phone:string, withMask:boolean = false,hidden:boolean=false) {
    if(!phone) return ''
    if(withMask ){
        let mask : any;
        if(hidden){
            mask = new Mask({ mask: '+1 (***) ***-####' })

        }else{
            mask = new Mask({ mask: '+1 (###) ###-####' })
        }
        return mask.masked(phone);
    }else
        return `+1${phone}`.replaceAll('-','').replaceAll(' ','')
}

function trimmedPhoneWithoutCountryCode(phone:string){
    let trimmedPhone = '';
    try{
        if(phone){
            let phoneWithoutCountryCode = phone.toString().replace('+1','').replaceAll('-','')
            phoneWithoutCountryCode = phoneWithoutCountryCode.length>10?phoneWithoutCountryCode.toString().substring(1):phoneWithoutCountryCode;
            trimmedPhone = phoneWithoutCountryCode;
        }
    }catch (e) {
    }
    return trimmedPhone;
}

const getUserType = (rollId: number) => {
    if (rollId == UserRole.ROLE_SC_ADMIN) {
        return "Admin"
    } else if (rollId == UserRole.ROLE_SC_STUDENT) {
        return "Student"
    } else if (rollId == UserRole.ROLE_SC_INSTRUCTOR) {
        return "Instructor"
    } else if (rollId == UserRole.ROLE_SC_PARENT) {
        return "Parent"
    } else if (rollId == UserRole.ROLE_SC_SUBJECT_TEACHER) {
        return "Subject Teacher"
    } else if (rollId == UserRole.ROLE_SC_POWER_ADMIN) {
        return "Power Admin"
    } else if (rollId == UserRole.ROLE_SC_TEACHER) {
        return "Staff"
    } else {
        return "Other"
    }
}

function getHiddenPhone  (phone:string) {
    let finalHiddenPhone = '';
    try{
        let lastFourDigit = phone.slice(phone.length-4)
        finalHiddenPhone = '***-***-'+ lastFourDigit;
    }catch (e){
        console.log(e)
        finalHiddenPhone = phone
    }
    return finalHiddenPhone;
}

function convertDBDateToDateTime(date: string) {
    const formattedDate = moment(date, 'YYYYMMDDHHmm').format('MM/DD/YYYY hh:mm A');
    return formattedDate;
}

//202307250001 => MM/DD/YYYY
function convertDBDateToDate(dateTimestamp: string) {
    // Convert timestamp to a Moment object
    const momentDate = moment(dateTimestamp, "YYYYMMDDHHmm");
    // Format the date to MM/DD/YYYY
    return  momentDate.format("MM/DD/YYYY");
}

//202307250001 => MM/DD/YYYY
function convertDBDateToTime(dateTimestamp: string) {
    // Convert timestamp to a Moment object
    const momentDate = moment(dateTimestamp, "YYYYMMDDHHmm");
    // Format the date to HH:mm
    const x =momentDate.format("HH:mm");
    return  momentDate.format("HH:mm");
}

function convertTimeToHHMM(timeVal) {
    // Check if the input is an object and has the required properties
    if (
        typeof timeVal === 'object' &&
        timeVal !== null &&
        'hours' in timeVal &&
        'minutes' in timeVal &&
        'seconds' in timeVal
    ) {
        // Extract hours, minutes, and seconds
        const { hours, minutes, seconds } = timeVal;

        // Create a moment object with the provided time
        const time = moment({ hours, minutes, seconds });

        // Check if the moment object is valid
        if (time.isValid()) {
            return time.format('HH:mm');
        }
    } else {
        return timeVal;
    }
}

function convertToMMDDYYYY(dateString) {
    // Regular expression to check if the date is already in MM/DD/YYYY format
    const mmddyyyyRegex = /^\d{2}\/\d{2}\/\d{4}$/;

    // Check if the date string matches the MM/DD/YYYY format
    if (mmddyyyyRegex.test(dateString)) {
        return dateString;
    }
    const format = "YYYYMMDDHHmm"
    // Parse the date string using Moment.js
    const date = moment(dateString, format);


    // Check if the parsed date is valid
    if (date.isValid()) {
        // Format the date to MM/DD/YYYY
        return date.format('MM/DD/YYYY');
    } else {
        // Return null or an error message if the date is invalid
        return null;
    }
}

function convertToUTCDateTime(inputDateTime) {
    // Parse input date string in "YYYYMMDDHHmm" format
    if(inputDateTime) {
        const parsedDateTime = moment(inputDateTime, 'YYYYMMDDHHmm');
        // Convert to UTC
        const utcDateTime = parsedDateTime.utc();
        // Format UTC date back to "YYYYMMDDHHmm" format
        const formattedUTCDateTime = utcDateTime.format('YYYYMMDDHHmm');
        return formattedUTCDateTime;
    }
}

function getUserLocalTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function convertUTCTimeToLocalTimezone(utcTimeString: Number | String) {
    try {
        const currentTimeZone = getUserLocalTimezone();

        // Parse the UTC time string
        const utcMoment = moment.utc(`${utcTimeString}`, "YYYYMMDDHHmm");
        // Convert to the user's timezone
        const localMoment = utcMoment.tz(currentTimeZone);
        // Format the local time to YYYYMMDDHHmm
        return localMoment.format("YYYYMMDDHHmm");
    } catch(err) {
        console.log(`helper.convertUTCTimeToLocalTimezone: `, err)
    }
}
const getTodayDate = ()=>{
    return moment().format('YYYY-MM-DD');
}

//2024-02-27 => 20240227
const getDBDateFromStringDate = (date: string|undefined) => {
    try{
        if(moment(date, 'YYYY-MM-DD').isValid()){
            return moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
        }else{
            return '';
        }
    }catch(e){
        return '';
    }
}
const getDateFromNumber = (date: number) => {
    return moment(String(date)).format('YYYY-MM-DD');
}
function  convertTimeToObj (timeString: string)  {
    const [hoursStr, minutesStr] = timeString.split(':');
    const hours = parseInt(hoursStr);
    const minutes = parseInt(minutesStr);
    return {
        hours: hours,
        minutes: minutes,
        seconds: 0  // Assuming seconds are always 0 in our case
    };
}

function getUsageCategoryText(usageCategory: PlanUsageCategory) {
    switch (usageCategory) {
        case PlanUsageCategory.RECHARGE:
            return 'Recharge';
        case PlanUsageCategory.SEND_TEXT:
            return 'Send Text';
        case PlanUsageCategory.SEND_TEXT_FAILED_READJUSTMENT:
            return 'Send Text Failed Readjustment';
        default:
            return usageCategory;
    }
}

function isNumeric(str) {
    const num = Number(str);
    return !isNaN(num);
}

// String Format example: "paymentInvoice=1760;fee=185;participantInvoice=1472"
function parseStringToObject(str) {
    const obj = {};
    const keyValuePairs = str.split(';');

    keyValuePairs.forEach(pair => {
        const [key, value] = pair.split('=');
        obj[key] = isNumeric(value) ? Number(value) : value;
    });

    return obj;
}

function isMenuOpen(childUrls:string[]) {
    for(const url of childUrls) {
        if(isCurrentPath(url)){
            return true;
        }
    }
}

function isCurrentPath(url:string) {
    const route = useRoute();
    return route.fullPath.endsWith(url);
}

export {
    getUserType,
    getDateFromNumber,
    getDBDateFromStringDate,
    getDateFromDBDate,
    getDateFromUnixTime,
    getDateFromDBDate1,
    getDateFromDBDate2,
    getDateFromDBDate3,
    getDateFromDBDate4,
    getDateFromDBDate5,
    getDateFromDBDate6,
    getDateFromDBDate8,
    getDateFromDBDateTime,
    getTimeFromDBTime,
    getTimeFromDBDateTime,
    getPaymentPeriodByDBDate,
    getDBDateFromDateStringStartOrEndMinute,
    getTodayDateTimeString,
    getBefore7DayDateTimeString,
    getTimeFromStringTime,
    getDateFromStringDate,
    getATemporaryEmail,
    isTemporaryEmail,
    validateEmail,
    isValidEmail,
    getStringFromTime,
    getMomentDateFromDBDate,
    getCurrentTimeDbFormat,
    getDateFromString,
    getDateFromDBDate7,
    getDateTimeStringFromDate,
    getDateAgo,
    getDateTimeFromMoment,
    getDateStringFromDBDate3,
    getStartDateOfCurrentMonthDbFormat,
    getTodayString,
    getCurrentYearString,
    getDateStringFromDBDate,
    getStringDateFormat,
    getStringFromDate,
    getFormattedDateFromDateString,
    getDateBeforeDate,
    sortedListByDBDateTimeKey,
    getFormattedDateFromDateString1,
    trimmedPhoneWithoutCountryCode,
    getPhoneWithCountryCode,
    getMomentFromDBDate,
    getHiddenPhone,
    getOnlyDayFromDateString,
    getMomentFromDateString,
    convertDBDateToDateTime,
    convertTimeToHHMM,
    convertToMMDDYYYY,
    convertDBDateToDate,
    convertDBDateToTime,
    convertToUTCDateTime,
    getUserLocalTimezone,
    convertUTCTimeToLocalTimezone,
    convertTimeToObj,
    getDBDateFromDateStringStartOrEndMinute2,
    getUsageCategoryText,
    getTodayDate,
    parseStringToObject,
    getDateFromDBDateToMMDDYYTime,
    isCurrentPath,
    isMenuOpen,
};
